import servicesArrow from './icons/services-arrow.svg';
import logoWhite from './icons/logo-white.svg';
import rectangle from './icons/rectangle.png';
import logoMini from './icons/logo-mini.svg';
import triangle from './icons/triangle.png';
import smallTriangle from './icons/triangle.svg';
import circle from './icons/circle.svg';
import square from './icons/square.svg';

export {
  servicesArrow,
  logoWhite,
  rectangle,
  logoMini,
  triangle,
  smallTriangle,
  circle,
  square,
}
