import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';

import { GridWrapper, background, StyledImageWrapper } from '.';
import { rectangle } from '../../images';

const GridWrapperStyled = styled(GridWrapper)`
  padding-left: 100px;
`;

export const RectangleImage = styled.div`
  ${background}
  width: 40%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
`;

const StyledImage = styled(StyledImageWrapper)`
  z-index: 0;
`;

const GridSubOne = ({ children, image = null }) => {
  return (
    <GridWrapperStyled>
      {image && (
        <StyledImage>
          <Image fluid={image} />
        </StyledImage>
      )}
      <RectangleImage img={rectangle} />
      {children}
    </GridWrapperStyled>
  )
}

export default GridSubOne;
