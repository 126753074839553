import React, { useState } from 'react';
import styled from 'styled-components';
import { StyledHeading, StyledText } from './shared';
import { logoWhite } from '../images';
import { Input, TextArea } from './shared/FormElements';
import GridContainer from './shared/GridSubMain';
import GridSubOne from './shared/GridSubOne';
import GridForm from './shared/GridSubTwo';

const StyledLogo = styled.img`
  margin-bottom: 18px;
`;

const FormWrapper = styled.div`
  flex-direction: column;
  padding: 0 68px;
`;

const Form = styled.form`
  width: 100%;
  height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;

const SubmitButton = styled.button`
  width: 105px;
  height: 54px;
  padding: 18px;
  border-radius: 27px;
  border: ${({ theme }) => `2px solid ${theme.colors.primary}`};
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.family.nunito};
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: 2.52px;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
`;

const Label = () => (<>Email<sup>*</sup></>);

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  function handleFormSubmit() {}

  return (
    <GridContainer>
      <GridSubOne>
        <StyledLogo src={logoWhite} alt="logo" />
        <StyledHeading>Let's work<br />together!</StyledHeading>
        <StyledText>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel repudiandae ducimus enim! Laudantium sint qui, nesciunt dicta vel in? Fuga accusamus voluptatum quo nihil a nobis quisquam optio, quaerat sapiente?</StyledText>
        <StyledText>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</StyledText>
      </GridSubOne>
      <GridForm>
        <FormWrapper className="flexbox">
          <Form onSubmit={() => handleFormSubmit()}>
            <Input label="Name" value={name} onChange={(e) => setName(e.target.value || '')} placeholder="Enter Name" />
            <Input label={<Label />} value={email} onChange={(e) => setEmail(e.target.value || '')} placeholder="Enter Email" />
            <TextArea label="Message" value={message} onChange={(e) => setMessage(e.target.value || '')} placeholder="Enter Message" />
            <SubmitButton type="submit">submit</SubmitButton>
          </Form>
        </FormWrapper>
      </GridForm>
    </GridContainer>
  )
}

export default ContactForm;
