import React from 'react';
import styled from 'styled-components';

const GridWrapper = styled.div`
  grid-template-columns: 1fr 2fr 1fr;
  height: 100vh !important;
`;

const LandingLayout = ({ children }) => (
  <GridWrapper className="grid">
    {children}
  </GridWrapper>
);

export default LandingLayout;
