import React from 'react';
import Image from 'gatsby-image';
import styled from 'styled-components';
import { StyledRectangle, StyledImageWrapper } from './shared';
import { logoWhite, rectangle } from '../images';

const StyledGrid = styled.div`
  position: relative;
  padding: 36px;
  display: grid;
`;

const GridLeft = ({ children, image }) => {
  return (
    <StyledGrid>
      <StyledImageWrapper>
        <Image fluid={image} />
      </StyledImageWrapper>
      <StyledRectangle img={rectangle} />
      <img src={logoWhite} alt="logo" />
      {children}
    </StyledGrid>
  )
}

export default GridLeft;
