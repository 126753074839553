import React from 'react';
import styled from 'styled-components';
import { SideNav } from '../components';

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 55px 1fr 55px;
  height: 100vh;
`;

const WhiteElelment = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
`;

const ContactLayout = ({ children, page }) => {
  return (
    <GridWrapper>
      <SideNav active={page} />
      {children}
      <WhiteElelment />
    </GridWrapper>
  )
}

export default ContactLayout;

