import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ContactButton } from '.';
import { GridWrapper, StyledHeading, StyledText } from './shared';

const StyledNodes = styled.div`
  padding: 9px 0;
`;

const StyledNode = styled.h6`
  display: inline-block;
  color: ${({ theme }) => theme.colors.grey1};
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.67px;
  font-family: ${({ theme }) => theme.fonts.family.vollkorn};
`;

const StyledDot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.grey2};
  margin: 0 15px;
`;

const StyledSubtitle = styled.h3`
  font-family:  ${({ theme }) => theme.fonts.family.nunito};
  font-size: 27px;
  font-weight: normal;
  line-height: 1.33;
  color: ${({ theme }) => theme.colors.green1};
  padding: 0;
  margin: 9px 0 18px 0;
`;

const GridText = ({ heading = '', nodes = [], paragraph = '', isInverse = false, subtitle = false }) => (
  <GridWrapper className={isInverse ? 'inverse' : ''}>
    <StyledHeading className="main-heading">
      {heading}
    </StyledHeading>
    <StyledNodes className="flexbox page-nodes">
      {nodes.map((node, index) => {
        return (
          <Fragment key={`${index}_title_nodes`}>
            <StyledNode>{node}</StyledNode>
            {index + 1 < nodes.length && <StyledDot />}
          </Fragment>
        )
      })}
    </StyledNodes>
    {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
    <StyledText className="subtext">
      {paragraph}
    </StyledText>
    <ContactButton />
  </GridWrapper>
);

export default GridText;
