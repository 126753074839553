import React from 'react';
import styled, { css } from 'styled-components';

const FormElement = styled.div`
  position: relative;
  margin: 18px 0;
  width: 100%;
  max-width: 404px;
`;

const InputLabel = styled.label`
  position: absolute;
  top: -10px;
  left: 18px;
  padding: 0 3px;
  z-index: 1;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.58px;
  &::after {
    content: " ";
    width: 100%;
    height: 13px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
  display: inline-block;
  font-family: ${({ theme }) => theme.fonts.family.vollkorn};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.green1};
  backdrop-filter: blur(5px);
`;

const mixin = css`
  box-shadow: none !important;
  outline: none;
  font-family: ${({ theme }) => theme.fonts.family.nunito};
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: 0.86px;
  width: 100%;
  padding: 8px 18px;
  border: ${({ theme }) => `0.5px solid ${theme.colors.black}`};
  color: ${({ theme }) => theme.colors.black};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: all 0.2s ease-in;
  &:focus {
    border: ${({ theme }) => `0.5px solid ${theme.colors.primary}`};
  }
`;

const StyledInput = styled.input`
  ${mixin};
  height: 40px;
`;

const StyledTextarea = styled.textarea`
  ${mixin};
  height: 157px;
  resize: none;
`;

export const Input = ({ value, onChange, label, placeholder }) => {
  return (
    <FormElement>
      <InputLabel>{label}</InputLabel>
      <StyledInput value={value} onChange={onChange} type="text" placeholder={placeholder} />
    </FormElement>
  )
}

export const TextArea = ({ value, onChange, label, placeholder }) => {
  return (
    <FormElement>
      <InputLabel>{label}</InputLabel>
      <StyledTextarea value={value} onChange={onChange} type="text" placeholder={placeholder} />
    </FormElement>
  );
}