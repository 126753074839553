import React from 'react';
import styled from 'styled-components';
import { ServicesButton } from '.';

const GridWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
`;

const GridRight = () => (
  <GridWrapper className="flexbox">
    <ServicesButton isAnimated={true} />
  </GridWrapper>
);

export default GridRight;
