import React from 'react';
import styled, { keyframes } from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import { servicesArrow } from '../images';

const animatePulsation = props => keyframes`
  0% {
    box-shadow: 1px 1px 2px 0 #000000;
    border: 3px solid ${props.theme.colors.green1};
  }
  50% {
    box-shadow: 0 0 50px 0 rgba(0, 193, 187, 1);
    border: 3px solid ${props.theme.colors.primary};
  }
  100% {
    box-shadow: 0 0 50px 0 rgba(0, 193, 187, 0.5);
    border: 3px solid ${props.theme.colors.green1};
  }
`;

const ButtonWrapper = styled.div`
  cursor: pointer;
  width: 178px;
  height: 178px;
  box-shadow: ${({ theme }) => `1px 1px 2px 0 ${theme.colors.pureblack}`};
  border: ${({ theme }) => `3px solid ${theme.colors.green1}`};
  flex-direction: column;
  align-items: flex-start !important;
  border-radius: 50%;
  padding: 31px;
  &.animate {
    animation: ${animatePulsation} 1.5s ease-in-out infinite;
    animation-iteration-count: 3;
  }
  &:hover {
    transition: all 0.4s ease-in-out;
    box-shadow: 0 0 50px 0 rgba(0, 193, 187, 0.6);
    border: ${({ theme }) => `3px solid ${theme.colors.primary}`};
    background-color: ${({ theme }) => theme.colors.primarydark};
    span, img {
      transform: translateX(50%);
    }
    span {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const ServicesText = styled.span`
  font-family: ${({ theme }) => theme.fonts.family.nunito};
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: 0.67px;
  text-align: left;
  color: ${({ theme }) => theme.colors.grey1};
  transition: all 0.4s ease-in-out;
  transform: translateX(0);
`;

const ArrowImage = styled.img`
  transition: all 0.4s ease-in-out;
  transform: translateX(calc(100% - 31px));
`;

const ServicesButton = ({ isAnimated }) => (
  <AniLink to="/services" swipe direction="left">
    <ButtonWrapper className={`flexbox ${isAnimated ? 'animate' : ''}`}>
      <ServicesText>Services</ServicesText>
      <ArrowImage src={servicesArrow} />
    </ButtonWrapper>
  </AniLink>
)

export default ServicesButton;
