import styled, { css } from 'styled-components';

export const GridWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  padding: 50px 81px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  &.inverse {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    .page-nodes, .subtext {
      color: #999999;
      h6 {
        font-weight: normal;
      }
    }
    .subtext {
      line-height: 2;
    }
    .contact-button {
      color: ${({ theme }) => theme.colors.black};
      .button-content {
        background-color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;

export const StyledHeading = styled.h1`
  font-family: ${({ theme }) => theme.fonts.family.vollkorn};
  font-size: 45px;
  line-height: 55px;
  letter-spacing: 2.48px;
  font-weight: 400;
  margin: 0;
`;

export const StyledText = styled.p`
  font-size: 18px;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.grey2};
  margin: 9px 0 25px 0;
  padding: 0;
  max-width: 600px;
`;

export const StyledImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: -1px;
  bottom: 0;
  display: grid;
  z-index: -1;
  .gatsby-image-wrapper {
    height: 100vh;
  }
`;

export const background = css`
  background-image: ${({ img }) => `url(${img})`};
  background-size: 100% 100vh;
`;

export const StyledRectangle = styled(StyledImageWrapper)`
  ${background}
`;
