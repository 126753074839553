import React from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { logoMini } from '../images';

const Row = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  padding-left: 11px;
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Nav = styled.nav`
  writing-mode: vertical-lr; 
  transform: rotate(180deg);
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
`;

const NavItem = styled.span`
  padding: 21px 8px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.family.nunito};
  border-left: ${({ theme }) => `2px solid ${theme.colors.white}`};
  display: block;
  &:first-of-type {
    padding-top: 41px;
  }
  ${({ disabled, theme }) => disabled && `border-left: 0; color: ${theme.colors.grey3};cursor: default;`};
`;

const SideNav = ({ active = '' }) => {
  return (
    <Row>
        <AniLink
          to="/"
          swipe
          direction="right"
        >
          <img src={logoMini} alt="logo minimized" />
        </AniLink>
        <Nav>
          <AniLink to="/" swipe direction="right">
            <NavItem disabled={active === 'home'}>
              Home
            </NavItem>
          </AniLink>
          <AniLink to="/services" swipe direction="right">
            <NavItem disabled={active === 'services'}>
              Services
            </NavItem>
          </AniLink>
          <AniLink to="/contact" swipe direction="right">
            <NavItem disabled={active === 'contact'}>
              Contact
            </NavItem>
          </AniLink>
        </Nav>
      </Row>
  )
}

export default SideNav;

