import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetComponent = ({ title, href }) => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>{title}</title>
    <link rel="canonical" href={href} />
  </Helmet>
);

export default HelmetComponent;

