import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { StyledImageWrapper } from './shared';

const StyledWrapper = styled.div`
  padding: 20%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  transition: all 0.5s ease-out;
  &:hover {
    z-index: 10;
    box-shadow: rgb(0 0 0 / 75%) 0px 0px 8px 3px;
    transform: scale(1.1);
    .styled-dimmer {
      background-color: transparent;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(8, 57, 60, 0.6) 75%, #0a2126);
    }
  }
  cursor: pointer;
`;

const StyledDimmer = styled.div`
  position: absolute;
  background-color: rgba(10, 33, 38, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.5s ease-out;
`;

const Header = styled.p`
  font-family: ${({ theme }) => theme.fonts.family.vollkorn};
  font-size: 18px;
  letter-spacing: 0.86px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Text = styled.span`
  font-family: ${({ theme }) => theme.fonts.family.nunito};
  line-height: 1.43;
  letter-spacing: 0.67px;
  font-size: 14px;
  font-weight: 300;
  z-index: 1;
  margin: 9px 0;
`;

const Icon = styled.img`
  margin-left: 12px;
`;

const LearnMore = styled.span`
  font-family: ${({ theme }) => theme.fonts.family.nunito};
  line-height: 1.5;
  letter-spacing: 0.58px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  z-index: 5;
`;

const ImageWrapper = styled(StyledImageWrapper)`
  right: 0;
  z-index: 0;
`;

const ServiceCard = ({ header, text, learnMore, icon, image }) => {
  return (
    <AniLink to={learnMore} swipe direction="left">
      <StyledWrapper>
        <StyledDimmer className="styled-dimmer" />
        <ImageWrapper>
          <Image fluid={image} />
        </ImageWrapper>
        <Header>
          {header}
          <Icon src={icon} />
        </Header>
        <Text>{text}</Text>
          <LearnMore>Learn more</LearnMore>
      </StyledWrapper>
    </AniLink>
  )
}

export default ServiceCard
