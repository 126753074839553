import React from 'react';
import styled from 'styled-components';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns};
  background-color: ${({ theme }) => theme.colors.white};
`;

const GridSubMain = ({ children, columns = '5fr 7fr' }) => {
  return (
    <GridContainer columns={columns}>
      {children}
    </GridContainer>
  )
}

export default GridSubMain;
