import React from 'react';
import styled from 'styled-components';

import { RectangleImage } from './GridSubOne';
import { triangle } from '../../images';

const GridForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
`;

const RectangleImageRight = styled(RectangleImage)`
  right: unset;
  left: 0;
  width: 100%;
`;

const ImgWrap = styled.div`
  position: relative;
  height: 100%;
`;

const GridSubTwo = ({ children }) => {
  return (
    <GridForm>
      <ImgWrap>
        <RectangleImageRight img={triangle} />
      </ImgWrap>
      {children}
    </GridForm>
  )
}

export default GridSubTwo;
