import React from 'react';
import styled, { keyframes } from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const animateGradient = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const animateDot = keyframes`
  0% {
    offset-distance: 0%;
  }
  100% {
    offset-distance: 100%;
  }
`;

const StyledButton = styled.button`
  outline: none;
  border: none;
  overflow: hidden;
  position: relative;
  border-radius: 30px;
  padding: 2px;
  color: ${({ theme }) => theme.colors.white};
  background-color: transparent;
  font-family: ${({ theme }) => theme.fonts.family.nunito};
  text-transform: uppercase;
  position: relative;
  width: 151px;
  height: 57px;
  font-size: 14px;
  font-weight: bold;
  line-height: 2.07;
  letter-spacing: 2.52px;
  cursor: pointer;
  &:hover {
    .gradinet-div {
      background-image: linear-gradient(116deg, rgba(10,33,38,1) 0%, rgba(10,33,38,1) 40%, #00c1bb 100%);
      animation: ${animateGradient} 3s ease-in-out infinite;
    }
  }
`;

const StyledContent = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  width: 100%;
  height: 100%;
  border-radius: 30px;
  position: relative;
`;

const StyledDot = styled.div`
  position: absolute;
  left: 50px;
  bottom: -3px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.black};
  border: 2px solid #028f8c;
  z-index: 2;
`;

const GradientDiv = styled.div`
  position: absolute;
  border-radius: 30px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 152px;
  height: 152px;
  background-image: linear-gradient(116deg, rgba(10,33,38,1) 0%, rgba(10,33,38,1) 40%, rgba(2,143,140,1) 100%);
`;

const AniLinkStyled = styled(AniLink)`
  position: relative;
  &:hover {
    .moving-dot {
      offset-path: path('m 4,5 l-30,0 c-30,0 -30,-55 0,-55 l100, 0 c 35,0 35,55 0, 55 z');
      animation: ${animateDot} 3s infinite normal ease-in-out;
    }
  }
`;

const ContactButton = () => {
  return (
    <AniLinkStyled to="/contact" swipe direction="left">
      <StyledButton className="contact-button">
        <GradientDiv className="gradinet-div" />
        <StyledContent className="flexbox button-content">contact us</StyledContent>
      </StyledButton>
      <StyledDot className="moving-dot" />
    </AniLinkStyled>
  )
}

export default ContactButton;
